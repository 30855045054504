<template>
	<div class="d-flex flex-column h-100">
		<navbar v-if="$route.name!='login' && $route.name!='promotion'"/>
		<div class="flex-grow-1 d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center h-0 bg-purple-50">
			<router-view/>
		</div>
		<foot v-if="$route.name!='login' && $route.name!='promotion'" class="d-none d-sm-flex"></foot>
	</div>
</template>
<script>
    import navbar from '@/components/navbar.vue'; 
    import foot from '@/components/footer.vue'; 
    
    export default{
      name: 'App',
      components: {
        navbar,
        foot
      },
    };
</script>
<style>
	.overflow-y-scroll{
		overflow-y: scroll;
	}
	.overflow-x-hidden{
		overflow-x: hidden;
	}
	.scroll-none::-webkit-scrollbar{
		display: none;
	}
	html,body{
		height: 100%;
		overflow: hidden;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		height: 100%;
	}
	nav {
		padding: 30px;
	}
	nav a {
		font-weight: bold;
		color: #2c3e50;
	}
	nav a.router-link-exact-active {
		color: #42b983;
	}
	.index-1{
		z-index: 1;
	}
	.h-0{
		height: 0;
	}
	.h-100{
		height:100%;
	}
	.bg-purple{
		background-color: rgb(55, 27, 67);
	}
	.bg-purple-50{
		background-color:rgb(236, 236, 236);
	}
	@media (max-width:575px) {
		.position-xs-absolute{
			position: absolute;
		}
	}
	@media (min-width:576px) and (max-width:767px) {
		.position-sm-relative{
			position:relative;
		}
	}
	@media (min-width:576px) {
		.h-sm-auto{
		height: auto;
		}
	}
</style>
